body {
  background-color: #dbe7e7; }

.header {
  text-align: center;
  margin: auto; }

.cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

.cards img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

/* Container to hold the images on the card */
.image {
  height: 450px;
  position: relative; }

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  transition: 0.3s;
  flex: 1 0 500px;
  box-sizing: border-box;
  margin: 1rem .25em;
  border-radius: 25px; }

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  transform: scale(1.02); }

.name {
  text-align: center; }

.container {
  padding: 2%;
  border-top: groove 2px; }

.details {
  list-style-type: none; }

/* Responsive screen sizing */
/* Adapted from: https://getflywheel.com/layout/flexbox-create-modern-card-design-layout/ */
@media screen and (min-width: 40em) {
  .card {
    flex: 0 1 calc(50% - 1em); } }

@media screen and (min-width: 60em) {
  .card {
    flex: 0 1 calc(25% - 1em); } }
